var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v(" Datos del lote de inventario ")]),_c('div',{staticClass:"text-gray mb-5"},[_vm._v(" Por favor registre los datos del lote de inventario para continuar ")])])]),_c('b-row',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Código")]),_c('b-form-input',{attrs:{"id":"codigo","type":"text","placeholder":"Ej: 001","state":_vm.validateState('codigo'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.lote_inventario.codigo.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.lote_inventario.codigo, "$model", $$v)},expression:"\n                                        $v.lote_inventario.codigo.$model\n                                    "}}),(
                                        !_vm.$v.lote_inventario.codigo.$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.lote_inventario.codigo.$model .length)+" / "+_vm._s(_vm.$v.lote_inventario.codigo.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.lote_inventario.codigo.maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.lote_inventario.codigo.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.lote_inventario.codigo.required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Descripción")]),_c('b-form-input',{attrs:{"id":"nombre","type":"text","placeholder":"Ej: Mi lote de inventario","state":_vm.validateState('nombre'),"disabled":_vm.read_only},model:{value:(
                                        _vm.$v.lote_inventario.nombre.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.lote_inventario.nombre, "$model", $$v)},expression:"\n                                        $v.lote_inventario.nombre.$model\n                                    "}}),(
                                        !_vm.$v.lote_inventario.nombre.$anyError
                                    )?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.lote_inventario.nombre.$model .length)+" / "+_vm._s(_vm.$v.lote_inventario.nombre.$params .maxLength.max)+") ")]):_vm._e(),(
                                        !_vm.$v.lote_inventario.nombre.maxLength
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.lote_inventario.nombre.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                        !_vm.$v.lote_inventario.nombre.required
                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"fecha_vencimiento"}},[_vm._v("Fecha vencimiento")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"fecha_vencimiento","type":"text","placeholder":"AAAA-MM-DD","state":_vm.validateState(
                                                'fecha_vencimiento'
                                            ),"disabled":_vm.read_only,"autocomplete":"null"},model:{value:(
                                            _vm.$v.lote_inventario
                                                .fecha_vencimiento.$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.lote_inventario
                                                .fecha_vencimiento, "$model", $$v)},expression:"\n                                            $v.lote_inventario\n                                                .fecha_vencimiento.$model\n                                        "}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"disabled":_vm.read_only,"button-only":"","right":"","locale":"en-US","state":_vm.validateState(
                                                    'fecha_vencimiento'
                                                ),"aria-controls":"fecha_vencimiento"},model:{value:(
                                                _vm.$v.lote_inventario
                                                    .fecha_vencimiento
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.lote_inventario
                                                    .fecha_vencimiento
                                                    , "$model", $$v)},expression:"\n                                                $v.lote_inventario\n                                                    .fecha_vencimiento\n                                                    .$model\n                                            "}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.lote_inventario.fecha_vencimiento
                                            .formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.lote_inventario.fecha_vencimiento
                                            .required}},[_vm._v(" Campo requerido ")])],1)],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }